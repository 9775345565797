import adminAxios from "../config/adminconfig";
import Axios from "../config/config";

//export const SIS_STORAGE = 'http://localhost/lu-sis/public/storage/';
//export const SIS_STORAGE = 'https://uat.icore.uk/storage';

export const SIS_STORAGE = 'https://dev.icore.uk/storage';





export const sisDateFormat = (inputDate) => {
    if (inputDate) {
        const dateParts = inputDate.split("-");

        // Rearrange the date parts to MM-DD-YYYY
        const formattedDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;

        return formattedDate;
    }

}

export const sisDateWithShortMonth = (inputDate) => {
    if (inputDate) {
        // Convert the date string to a Date object
        const dateObject = new Date(inputDate);

        // Get the day, month, and year from the Date object
        const day = dateObject.getDate();
        const month = dateObject.toLocaleString('default', { month: 'short' });
        const year = dateObject.getFullYear();

        // Format the date in DD MMM YYYY format
        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate;
    }

}


export const LOGIN_API = async (body) => {
    try {
        const response = await adminAxios.post('/admin-login', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Set Password
export const SET_PASSWORD_API = async (body) => {
    try {
        const response = await adminAxios.post('/reset-password', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const SET_USER_PASSWORD_API = async (body) => {
    try {
        const response = await adminAxios.post('/reset-password-user', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};

// Check API Token
export const CHECK_PASSWORD_TOKEN = async (body) => {
    try {
        const response = await adminAxios.post('/check-reset-password-token', body);
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const CHECK_TOKEN = async (body) => {
    try {
        const response = await adminAxios.post('/admin-token-check', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const DB_LIST = async (body) => {
    try {
        const response = await Axios.post('/role-tables', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ALL_SEMESTERS_LIST = async (body) => {
    try {
        const response = await adminAxios.post('/semester-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// ROLES

export const ROLE_LIST = async (body) => {
    try {
        const response = await Axios.post('/role-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_ROLE = async (body) => {
    try {
        const response = await Axios.post('/view-role', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-edit', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_ROLE = async (body) => {
    try {
        const response = await Axios.post('/role-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// USERS

export const USERS_LIST = async (body) => {
    try {
        const response = await Axios.post('/users-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_USER = async (body) => {
    try {
        const response = await Axios.post('/user-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_USER = async (body) => {
    try {
        const response = await Axios.post('/user-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_USER = async (body) => {
    try {
        const response = await Axios.post('/user-edit', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_USER = async (body) => {
    try {
        const response = await Axios.post('/user-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_USER = async (body) => {
    try {
        const response = await Axios.post('/user-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// USERS

export const STUDENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/students-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/student-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_STUDENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EMERGENCY_CONTACT = async (body) => {
    try {
        const response = await Axios.post('/student-emergency-contacts-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EMERGENCY_CONTACT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-emergency-contacts-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const APPLICATION_DOCUMENT = async (body) => {
    try {
        const response = await Axios.post('/student-documents-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const APPLICATION_DOCUMENT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-documents-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};




// categories

export const CATEGORIES_LIST = async (body) => {
    try {
        const response = await Axios.post('/categories-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-edit', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_CATEGORY = async (body) => {
    try {
        const response = await Axios.post('/categories-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// COURSES

export const COURSES_LIST = async (body) => {
    try {
        const response = await Axios.post('/courses-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_COURSE = async (body) => {
    try {
        const response = await Axios.post('/course-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEARCH_COURSE = async (body) => {
    try {
        const response = await Axios.post('/courses-search', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
// Rooms

// Add Room

export const ADD_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Rooms List 

export const CLASSROOMS_LIST = async (body) => {
    try {
        const response = await Axios.post('/classrooms-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Room Status


export const STATUS_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Room Delete 

export const DELETE_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Room Edit 
export const UPDATE_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EDIT_ROOM = async (body) => {
    try {
        const response = await Axios.post('/classroom-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Enrollments

// Add enrollment

export const ADD_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ENROLLMENTS_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/enrollment-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const ENROLLMENTS_UPDATE_POPUP = async (body) => {
    try {
        const response = await Axios.post('/student-update-profile', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}
// New Enrollment 
export const NEW_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const VIEW_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

// Enrollment List 

export const ENROLLMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-enrollments-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

//program enrollment list view 
export const PROGRAM_ENROLLMENTS_LIST_VIEW = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}

// ** Enrollment List New
export const STUDENTS_ENROLLMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/enrollments-list', body);
        return response;
    } catch (error) {
        console.log(error);
    }
};


export const STUDENTS_ENROLLMENTS_VIEW = async (body) => {
    try {
        const response = await Axios.post('/enrollment-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}


// Enrollment Status


export const STATUS_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Enrollment Delete 

export const DELETE_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/student-enrollment-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// Update Edit Enrollment 

export const EDIT_ENROLLMENT = async (body) => {
    try {
        const response = await Axios.post('/enrollment-programme-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// View Enrollment 



export const ADD_STUDENT_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/student_add', body);
        return response
    } catch (error) {
        console.log(error);
    }
}


// Program List 

export const PROGRAMS_LIST = async (body) => {
    try {
        const response = await adminAxios.post('/programmes-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_VIEW_API = async (body) => {
    try {
        const response = await Axios.post('/student-view', body);
        return response;
    } catch (error) {
        console.log(error)
    }
}

export const ADD_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const EDIT_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_PROGRAM = async (body) => {
    try {
        const response = await Axios.post('/programme-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};





// SUBSECTIONS

export const SUBSECTIONS_LIST = async (body) => {
    try {
        const response = await Axios.post('/sub-sections-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SUBSECTION = async (body) => {
    try {
        const response = await Axios.post('/sub-section-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};






// SUBSECTIONS

export const SECTIONS_LIST = async (body) => {
    try {
        const response = await Axios.post('/sections-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SECTION2 = async (body) => {
    try {
        const response = await Axios.post('/section-update2', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SECTION = async (body) => {
    try {
        const response = await Axios.post('/section-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};




// Calendar

export const CALENDARS_LIST = async (body) => {
    try {
        const response = await Axios.post('/calendars-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_CALENDAR = async (body) => {
    try {
        const response = await Axios.post('/calendar-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

// faculties 

export const FACULTIES_LIST = async (body) => {
    try {
        const response = await Axios.post('/faculties-list', body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_FACULTIES = async (body) => {
    try {
        const response = await Axios.post('/faculty-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;

    } catch (error) {
        console.error(error);
    }
};

export const DELETE_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const VIEW_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STATUS_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const UPDATE_FACULTY = async (body) => {
    try {
        const response = await Axios.post('/faculty-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};




// Calendar

export const SEMESTERS_LIST = async (body) => {
    try {
        const response = await Axios.post('/semesters-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_LIST = async (body) => {
    try {
        const response = await Axios.post('/calendars-schedules-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTERS_COURSES_DELETE = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ADD_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const EDIT_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const UPDATE_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STATUS_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-status', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const DELETE_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/semester-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};



// SEMESTER_REGISTRATION_VIEW

export const SEMESTER_REGISTRATION_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_REGISTRATION_ADD = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_REGISTRATION_STUDENT_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-student-view', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_REGISTRATION_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const ADD_CLASS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-add-section', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const REMOVE_CLASS_SECTION = async (body) => {
    try {
        const response = await Axios.post('/calendar-schedules-row-remove', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const TRANSFERRED_COURSE_ADD = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const TRANSFERRED_COURSE_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const TRANSFERRED_COURSE_LIST = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const TRANSFERRED_COURSE_DELETE = async (body) => {
    try {
        const response = await Axios.post('/transferred-course-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const SEMESTER_COURSE_REGISTRATION_ADD = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-add', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_COURSE_REGISTRATION_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-update', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const SEMESTER_COURSE_REGISTRATION_REMOVE = async (body) => {
    try {
        const response = await Axios.post('/semester-course-registration-delete', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const ACADEMIC_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/academic-semester', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ADMIN_STUDENTS_LISTING = async (body) => {
    try {
        const response = await Axios.post('/admin-students-listing', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENT_PROFILE = async (body) => {
    try {
        const response = await Axios.post('/student-profile', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENT_DOCUMENTS_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-documents-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENTS_DOCUMENTS_ADD = async (body) => {
    try {
        const response = await Axios.post('/students-documents-add', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const ACADEMIC = async (body) => {
    try {
        const response = await Axios.post('/academic', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const FORGOT_PASSWORD = async (body) => {
    try {
        const response = await adminAxios.post('/forgot-password', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// student panel apis new



export const STUDENT_PROFILE_API_LIST = async (body) => {
    try {
        const response = await Axios.post('/profile', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};
export const STUDENT_POPUP = async (body) => {
    console.log("body", body)
    try {
        const response = await Axios.post('/profile', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_ACADEMIC = async (body) => {

    try {
        const response = await Axios.post('/student-academic', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};


export const GRADES = async (body) => {
    try {
        const response = await Axios.post('/student-grades', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Student Self Online Registration

export const STRIPE_PAYMENT_INTENT_SERVICE = async (body) => {
    try {
        const response = await Axios.post('/registration-payment-intent', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
        } catch (error) {
        console.log(error);
    }
}

export const STRIPE_PAYMENT_COUPON = async (body) => {
    try {
        const response = await Axios.post('/apply-coupon', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
        } catch (error) {
        console.log(error);
    }
}

export const STRIPE_REGISTRATION_CHECKOUT = async (body) => {
    try {
        const response = await Axios.post('/registration-checkout-api', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
        } catch (error) {
        console.log(error);
    }
}

export const PLAN_UPFRONT_CHECKOUT_API = async (body) => {
    try {
        const response = await Axios.post('/plan-upfront-checkout-api', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
        } catch (error) {
        console.log(error);
    }
}



export const CREATE_ACCOUNT = async (body) => {
    try {
        const response = await Axios.post('/self-registration-sign-up', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const STUDENT_PROGRAMS_LIST = async (body) => {
    try {
        const response = await Axios.post('/student-programmes-list', body);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const PERSONAL_INFORMATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-info-contact-details-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_PERSONAL_INFORMATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-info-contact-details', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const DEMOGRAPHIC_INFORMATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/student-demographic-information', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_DEMOGRAPHIC_INFORMATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-application-documents-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const FINANCE_DECLARATION_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-finance-declaration-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_FINANCE_DECLARATION = async (body) => {
    try {
        const response = await Axios.post('/get-student-finance-declaration-submit-draft', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GET_AGREEMENT = async (body) => {
    try {
        const response = await Axios.post('/get-student-signature', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const AGREEMENT_SAVE = async (body) => {
    try {
        const response = await Axios.post('/add-student-signature', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const PROGRAM_CONCENTARTION_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/self-programm-registration', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GO_TO_DASHBOARD = async (body) => {
    try {
        const response = await Axios.post('/student-panel-login-dashboard', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const STUDENT_SEMESTER_REGISTRATION_COURSE_LISTING = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-course-listing', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const SELECTED_COURSE_IN_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/selected-course-in-semester', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const REMOVE_COURSE_IN_SEMESTER = async (body) => {
    try {
        const response = await Axios.post('/remove-course-in-semester', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const BILLING_PERIOD = async (body) => {
    try {
        const response = await Axios.post('/billing-period', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const REPEAT_COURSE_REQUEST = async (body) => {
    try {
        const response = await Axios.post('/request-repeat-course', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_DROP_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-add-drop-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_DROP_REQUEST_UPDATE = async (body) => {
    try {
        const response = await Axios.post('/request-repeat-course-update', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ADD_COURSE_INDENT = async (body) => {
    try {
        const response = await Axios.post('/add-course-indent', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const ADD_COURSE_INDENT_CHECKOUT_API = async (body) => {
    try {
        const response = await Axios.post('/add-course-indent-check', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const WITHDRAW_VIEW = async (body) => {
    try {
        const response = await Axios.post('/semester-registration-withdraw-view', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}


export const STUDENT_ACADEMIC_COURSE_LISTING = async (body) => {
    try {
        const response = await Axios.post('/student-academic-course-listing', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const SYSTEM_SETTING = async (body) => {
    try {
        const response = await Axios.post('/system-setting', body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}