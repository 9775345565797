import React, { useEffect, useState } from "react";
import SisLoader from "../../../widgets/loader";
import SemesterWiseGradeView from "../semesterWiseGradeView";
import { Button, Table, Tooltip } from "antd";
import { GRADES, sisDateFormat } from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../config/adminconfig";

const AnotherViewStudentsGrades = () => {
  const [loader, set_loader] = useState(true);
  const [table_list, set_Table_List] = useState([]);
  const [cgpa, set_CGPA] = useState("");
  const [confer_date, set_Confer_Date] = useState("");
  const [matriculation_semester, set_Matriculation_Semester] = useState("");
  const [matriculation_date, set_Matriculation_Date] = useState("");
  const [programme_title, set_Programme_Title] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const transferred_course_column = [
    {
      title: "Semester",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.transferred_semester}</span>
          </>
        );
      },
      // sorter: (a, b) => a.transferred_semester.localeCompare(b.transferred_semester),
    },
    {
      title: "University",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.transferred_institution}</span>
          </>
        );
      },
      // sorter: (a, b) => a.transferred_institution.localeCompare(b.transferred_institution),
    },
    {
      title: "Courses",
      width: "40%",
      render: (text, record) => {
        return (
          <>
            <span>{record.transferred_course} (TR)</span>
          </>
        );
      },
      sorter: (a, b) =>
        a.transferred_course.localeCompare(b.transferred_course),
    },
    {
      title: "Course Code",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.course_code}</span>
          </>
        );
      },
    },

    {
      title: "Attempt Credit",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_attempted.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Earned Credits",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_earn.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Grade",
      width: "12%",
      render: (text, record) => {
        return (
          <>
            <span>CR</span>
          </>
        );
      },
    },
  ];

  const university_course_column = [
    {
      title: "Courses",
      width: "40%",
      render: (text, record) => {
        return (
          <>
            {record.perquisite_courses == null ||
            record.perquisite_courses == "null" ? (
              <span>{record.course_title}</span>
            ) : (
              <span>
                {record.course_title}{" "}
                <span className="perquisite-courses">P</span>
              </span>
            )}
          </>
        );
      },
      sorter: (a, b) => a.course_title.localeCompare(b.course_title),
    },
    {
      title: "Course Code",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.course_code}</span>
          </>
        );
      },
    },
    {
      title: "Attempt Credit",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.given_credits.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Earned Credits",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.credit_earn.toFixed(1)}</span>
          </>
        );
      },
    },
    {
      title: "Grade",
      width: "15%",
      render: (text, record) => {
        return (
          <>
            <span>{record?.student_grade}</span>
          </>
        );
      },
    },
  ];

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", USER_DATA.id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("semester_id", USER_DATA?.current_semester);
    const VIEW_API_RESPONSE = await GRADES(FORM_DATA);

    if (VIEW_API_RESPONSE?.data?.status) {
      set_CGPA(VIEW_API_RESPONSE?.data?.cgpa);
      set_Confer_Date(VIEW_API_RESPONSE?.data?.enrollment?.confer_date);
      set_Matriculation_Semester(
        VIEW_API_RESPONSE?.data?.enrollment?.matriculation_semester
      );
      set_Matriculation_Date(
        VIEW_API_RESPONSE?.data?.enrollment?.matriculation_date
      );
      set_Programme_Title(VIEW_API_RESPONSE?.data?.enrollment?.programme_title);
      set_Table_List(VIEW_API_RESPONSE?.data?.tables);
      setFilteredData(VIEW_API_RESPONSE?.data?.tables);
      set_loader(false);
    }
  };
  
  useEffect(() => {
    VIEW_API();
  }, []);

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3 className="first-letter-capitalize">My Grades</h3>
              </div>
              <div className="theme-content-right-head">
                <Tooltip title="Download Unofficial Transcript PDF">
                  <Button
                    type="primary"
                    onClick={() =>
                      window.open(
                        BACKEND_URL +
                          "/unofficial-transcript-pdf/" +
                          JSON.parse(localStorage.getItem("sis_user_data"))
                            .token +
                          "/" +
                          JSON.parse(localStorage.getItem("sis_user_data")).id
                      )
                    }
                    ghost
                  >
                    Generate Unofficial Transcript
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <label className="first-letter-capitalize">
                  Program/Degree
                </label>
                <br />
              </div>
              <div className="col-2">
                <label className="first-letter-capitalize">
                  Matriculation Date
                </label>
                <br />
              </div>
              <div className="col-2">
                <label className="first-letter-capitalize">
                  Matriculation Semester
                </label>
                <br />
              </div>
              <div className="col-2">
                <label className="first-letter-capitalize">Confer Date</label>

                <br />
              </div>
              <Tooltip
                color="#fff"
                placement="bottom"
                title={
                  <div>
                    <h3 className="first-letter-capitalize">Cumulative GPA</h3>
                    <p>
                      A CGPA is your academic performance calculated as an
                      average of your GPA from all completed terms/semesters and
                      is used for an assessment of your overall academic
                      standings.
                    </p>
                  </div>
                }
              >
                <div className="col-2">
                  <label className="first-letter-capitalize">
                    Cumulative GPA(CGPA)
                  </label>
                  <br />
                </div>
              </Tooltip>
            </div>
            <br />
            <div className="row">
              <div className="col-3">
                <h4>{programme_title ? programme_title : "N/A"}</h4>
                <br />
              </div>
              <div className="col-2">
                <h3>
                  {matriculation_date == "null" || matriculation_date == null
                    ? "-"
                    : sisDateFormat(matriculation_date)}
                </h3>
                <br />
              </div>
              <div className="col-2">
                <h3>
                  {matriculation_semester ? matriculation_semester : "N/A"}
                </h3>
                {/* <br /> */}
              </div>
              <div className="col-2">
                <h3>
                  {confer_date == "null" || confer_date == null
                    ? "-"
                    : sisDateFormat(confer_date)}
                </h3>
                <br />
              </div>
              <div className="col-2">
                <h3>{cgpa?.toFixed(2)}</h3>
                <br />
              </div>
            </div>
            <hr />
          </div>
          {table_list?.length > 0 ? (
            <>
              {table_list?.map((item) => (
                <>
                  {(item?.transferred_course?.length > 0 ||
                    item?.university_course?.length) > 0 && (
                    <>
                      {item?.transferred_course?.length > 0 ? (
                        <>
                          <br></br>
                          <div
                            className="first-letter-capitalize"
                            style={{ fontWeight: "700" }}
                          >
                            Transferred Course
                          </div>
                          <br></br>
                          <Table
                            columns={transferred_course_column}
                            
                            dataSource={item?.transferred_course}
                            pagination={false}
                            summary={(pageData) => {
                              let totalAttemptedCredits = 0;
                              let totalEarnedCredits = 0;

                              pageData.forEach(
                                ({ credit_attempted, credit_earn }) => {
                                  totalAttemptedCredits += credit_attempted;
                                  totalEarnedCredits += credit_earn;
                                }
                              );
                              return (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={1}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={2}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={3}>
                                    <div>
                                      <strong>Total</strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={4}>
                                    <div>
                                      <strong>
                                        {totalAttemptedCredits.toFixed(1)}
                                      </strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={5}>
                                    <div>
                                      <strong>
                                        {totalEarnedCredits.toFixed(1)}
                                      </strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={6}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              );
                            }}
                          />
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.university_course?.length > 0 ? (
                        <>
                          <br></br>
                          <h3 style={{ color: "#3B82F6" }}>
                            {item?.semester?.title}
                          </h3>
                          <br></br>
                          <h3
                            className="first-letter-capitalize"
                            style={{ color: "#3B82F6" }}
                          >
                            Lincoln University
                          </h3>
                          <br/>
                          <Table
                            columns={university_course_column}
                            dataSource={item?.university_course?.filter(
                              (course) => course?.student_grade !== "-"
                            )}
                            pagination={false}
                            summary={(pageData) => {
                              let totalAttemptedCredits = 0;
                              let totalEarnedCredits = 0;

                              pageData.forEach(
                                ({
                                  given_credits,
                                  credit_earn,
                                  student_grade,
                                }) => {
                                  if (student_grade != "-") {
                                    totalAttemptedCredits += given_credits;
                                    totalEarnedCredits += credit_earn;
                                  }
                                }
                              );
                              return (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={1}>
                                    <div>
                                      <strong>Total</strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={2}>
                                    <div>
                                      <strong>
                                        {totalAttemptedCredits.toFixed(1)}
                                      </strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={3}>
                                    <div>
                                      <strong>
                                        {totalEarnedCredits.toFixed(1)}
                                      </strong>
                                    </div>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={4}>
                                    <div></div>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              );
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              <p
                style={{
                  margin: "50px 0",
                  textAlign: "center",
                  color: "red",
                  fontSize: "14px",
                }}
              >
                Student Grades Data Empty.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AnotherViewStudentsGrades;
