import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkoutForm';
import { STRIPE_PAYMENT_INTENT_SERVICE } from '../../../../apis/apis';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Button, Spin, notification } from 'antd';
import SisLoader from '../../../../widgets/loader';
import { useNavigate } from 'react-router-dom';

const StripePayment = () => {

    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const stripePublishableKey = "pk_test_51KnovYDPuQqUp5fc7c9m7zZUh9kKKaaM9MElVyiqYHL8C4ORMJjkDBMr7ZX8eDaaMpaMAES4ytFZ1bDKB0dU3fKc006FlW1Oes";
    const stripePromise = loadStripe(stripePublishableKey);
    const [enrollment, set_enrollment] = useState("");
    const [program, set_program] = useState('');
    const [view_amount, set_view_amount] = useState('');
    const navigate = useNavigate();

    if (enrollment) {

        var home_address = JSON.parse(enrollment?.home_country_address);
    }

    return (
        <>
        {localStorage.getItem("payment_status") == 0 && <>
            <div className='row'>
                <div className='online-form-step-box'>
                    <div className={'online-form-step'} onClick={() => navigate('/')}>
                        <div className='online-form-step-line'></div>
                        <span>1</span>
                        <h3>Basic Information </h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/demographic-information')}>
                        <div className='online-form-step-line'></div>
                        <span>2</span>
                        <h3>Demographic Information</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/finance-declaration')}>
                        <div className='online-form-step-line'></div>
                        <span>3</span>
                        <h3>Finance Declaration</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/agreement')}>
                        <div className='online-form-step-line'></div>
                        <span>4</span>
                        <h3>Agreement</h3>
                    </div>
                    <div className={'online-form-step'} onClick={() => navigate('/formpreview')}>
                        <div className='online-form-step-line'></div>
                        <span>5</span>
                        <h3>Preview</h3>
                    </div>
                    <div className={'online-form-step running'}>
                        <span>6</span>
                        <h3>Payment</h3>
                    </div>
                </div>
            </div>
           </>}
           {localStorage.getItem("payment_status") == 0 && 
                    <>
                     <br />
                     <hr />
                     <br />
                     </>
                    }
            <div className="row" style={{ marginLeft: "0px" }}>
                {/* <div className="col-5" style={{ borderRight: "1px solid #777" }}>
                    {loader ?
                        <SisLoader />
                        :
                        <>
                            <div>
                                <div className="theme-content-left-head">
                                    <h3>Student Details</h3>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-8 first-letter-capitalize">
                                        <p>Student Name</p>
                                        <h4 style={{ marginTop: "6px" }}>{enrollment?.first_name}{" "}{enrollment?.middle_name}{" "}{enrollment?.last_name}</h4>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-8 first-letter-capitalize">
                                        <p>Student Email Id</p>
                                        <h4 style={{ marginTop: "6px" }}>{enrollment?.primary_email}</h4>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-10 first-letter-capitalize">
                                        <p>Home Address</p>
                                        <h4 style={{ marginTop: "6px" }}>{home_address?.home_country_address1}{", "}{home_address?.home_country_address2}{", "}{home_address?.home_country_address_city}{" "}{home_address?.home_country_address_state}{", "}{home_address?.home_country_address_country}{", "}{home_address?.home_country_address_postal}</h4>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-10 first-letter-capitalize">
                                        <p>Program</p>
                                        <h4 style={{ marginTop: "6px" }}>{program?.title}</h4>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-10 first-letter-capitalize">
                                        <p>Payment</p>
                                        <h4 style={{ marginTop: "6px" }}>{view_amount}</h4>
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                </div> */}
                {/* <div className="col-7"> */}
                <div>
                    <div className="theme-content-left-head">
                        <h3>Payment</h3>
                    </div>
                    <br />
                </div>
                <div className="row">
                    <Elements
                        stripe={stripePromise}>
                        <CheckoutForm set_loader={set_loader} set_program={set_program} set_enrollment={set_enrollment} set_view_amount={set_view_amount} />
                    </Elements>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default StripePayment;